import React, { useState } from 'react';



const Uidesign = () => {
  const [toggleState,setToggleState] = useState(0);
  const toggleTab = (index) => {
    setToggleState(index);
  }
  
  return (
    <div className="skills_content" >
    <h3 className="skills_title">UIデザイン</h3>
    <div className="skills_box">
       <div className="skills_group">
          <div className="skills_data">
      <i class="uil uil-check-circle"></i>
  <div>
          <h3 className="skills_name">Illustrator</h3>
          <span className="skills_level">A</span>
          </div>
          </div>

          <div className="skills_data">
      <i class="uil uil-check-circle"></i>
　　　　　　<div>
          <h3 className="skills_name">Figma</h3>
          <span className="skills_level">A</span>
        </div>
          </div>

          <div className="skills_data">
      <i class="uil uil-check-circle"></i>
　　　　　<div>
          <h3 className="skills_name">Photoshop</h3>
          <span className="skills_level">A</span>
        </div>
          </div>

          

        
        
        </div>
    </div>
  

         
<span className="skills_button" onClick={() => toggleTab(1)}>Details<i className="uil uil-arrow-right skills_button-icon"></i></span>

      
<div className={toggleState === 1 ? "skills_modal active-modal" : "skills_modal"}>
          <div className="skills_modal-content">
          <i onClick={() => toggleTab(0)} class="uil uil-times skills_modal-close"></i>
          <h3 className="skills_modal-title">UIデザイン</h3>
          <p className="skills_modal-intro">Figmaでホームページのワイヤーフレームをデザインし、イラストレーターでlogoを制作し、Photoshopでアニメーションgifを作ることと画像を加工します。</p>
          </div>

</div>

</div>


        


    
  )
}

export default Uidesign