import React from 'react'
import {motion} from "framer-motion"


const  socialVariants = {
  initial:{
    y:500,
    opacity:0,
  },

  animate:{
    y:0,
    opacity:1,
    transition:{
      duration:2.5,
    },
  }
};

const Social = () => {
  return (
    <motion.div className="home_social" variants={socialVariants} initial="initial" animate="animate">
        <a href="https://www.linkedin.com/feed/" className="home_social-icon" target="_blank">
        <i class="uil uil-linkedin"></i>

        </a>

        <a href="https://www.instagram.com/" className="home_social-icon" target="_blank">
        <i class="uil uil-instagram"></i>
        </a>

        <a href="https://x.com/home" className="home_social-icon" target="_blank">
        <i class="uil uil-x"></i>
        </a>
    </motion.div>
  )
}

export default Social