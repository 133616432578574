import Image1 from "../../assets/work1.png";
import Image2 from "../../assets/work2.jpg";
import Image3 from "../../assets/work3.jpg";
import Image4 from "../../assets/work4.png";

export const Data = [
    { id: 1,
     image: Image1,
     title: "アニメ風加工",
     intro: "Photoshopで風景写真をアニメ調に加工する",
    },

    { id: 2,
        image: Image2,
        title: "名刺制作(表)",
        intro: "Figmaでデザインし、Illustratorで仕上がる",
       },

       { id: 3,
        image: Image3,
        title: "名刺制作(裏)",
        intro: "Figmaでデザインし、Illustratorで仕上がる",
       },

       { id: 4,
        image: Image4,
        title: "観光地紹介ホームページ制作",
        intro: "Figmaでデザインし、Reactで作成する",
       },
]