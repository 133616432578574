import React, { useRef } from 'react';
import "./Skills.css";
import Uidesign from './Uidesign';
import Frontend from './Frontend';
import Backend from './Backend';
import {animate, motion} from "framer-motion"

const skillvariants = {
  initial:{
    
    x:-300,
    opacity:0
  },
  animate:{
   
    x:0,
    opacity:1,
    transition:{
      duration:2,
      delayChildren:0.2,
      staggerChildren:0.2,

    }
  }
}

const oskillvariants = {
  initial:{
    scale:0,
    opacity:0,
  },

  animate :{
    scale:1,
    opacity:1,
    transition:{
      duration:2,
      delayChildren:0.2,
      staggerChildren:0.3,
  }
}
}


const Skills = () => {
  
  
  return (
    <motion.section className="skills section" id="skills"
    variants={skillvariants}
    initial="initial"
 whileInView="animate"
 viewport={{once:true}}
 
    >
        <motion.h2 className="section_title" variants={skillvariants}>Skills</motion.h2>
        <motion.span className="section_subtitle" variants={skillvariants}>スキル</motion.span>
        <div className="skills_container container grid" >
        <motion.span variants={oskillvariants}><Uidesign/></motion.span>
        <motion.span variants={oskillvariants}><Frontend/></motion.span>
        <motion.span variants={oskillvariants}><Backend/></motion.span>

    </div>

    </motion.section >
  )
}

export default Skills