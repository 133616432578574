import React, { useState } from 'react'

const Frontend = () => {
  
    const [toggleState,setToggleState] = useState(0);
    const toggleTab = (index) => {
      setToggleState(index);
    }

  return (
    
    <div className="skills_content">
    <h3 className="skills_title">フロントエンド</h3>
    <div className="skills_box">
       <div className="skills_group">
          <div className="skills_data">
      <i class="uil uil-check-circle"></i>
  　　　　　　<div>
          <h3 className="skills_name">HTML</h3>
          <span className="skills_level">A</span>
          </div>
          </div>

          <div className="skills_data">
      <i class="uil uil-check-circle"></i>
　　　　　　<div>
          <h3 className="skills_name">CSS</h3>
          <span className="skills_level">A</span>
        </div>
          </div>

          <div className="skills_data">
      <i class="uil uil-check-circle"></i>
　　　　　<div>
          <h3 className="skills_name">JavaScript</h3>
          <span className="skills_level">A</span>
        </div>
          </div>

          <div className="skills_data">
      <i class="uil uil-check-circle"></i>
　　　　　　　<div>
          <h3 className="skills_name">React</h3>
          <span className="skills_level">A</span>
          </div>
          </div>
        
          
        
        
        
        
        </div>
    </div>
  

         
<span className="skills_button" onClick={() => toggleTab(2)}>Details<i className="uil uil-arrow-right skills_button-icon"></i></span>

      
<div className={toggleState === 2 ? "skills_modal active-modal" : "skills_modal"}>
          <div className="skills_modal-content">
          <i class="uil uil-times skills_modal-close" onClick={() => toggleTab(0)}></i>
          <h3 className="skills_modal-title">フロントエンド</h3>
          <p className="skills_modal-intro">WEBサイトのフロントエンド開発する際、Reactでを複数のコンポーネントに分け、それぞれ作業を行うという形で進めます。framer motionなどReact用の様々なライブラリを使って、効率的に質が高いフロントエンドを開発します。</p>
          </div>

</div>

</div>
        
  )
}

export default Frontend