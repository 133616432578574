import React, { useRef } from 'react';
import "./Works.css";
import { Data } from './Data';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import {motion} from "framer-motion"

const workvariants = {
  initial:{
    
    x:-300,
    opacity:0
  },
  animate:{
   
    x:0,
    opacity:1,
    transition:{
      duration:1,
      staggerChildren:0.2,
      delayChildren:0.2,

    },
  }
}

const Works = () => {


  return (
    <motion.section className="works container section" id="work"
    variants={workvariants}
    initial="initial"
    
    whileInView="animate"
    viewport={{once:true}}>
      
        <motion.h2 className="section_title" variants={workvariants} >Works</motion.h2>
        <motion.span className="section_subtitle" variants={workvariants}>作品</motion.span>
    <Swiper className="works_container"
    loop={true}
    grabCursor={true}
    spaceBetween={24}
    pagination={{
      clickable: true,
    }}
    breakpoints={{
      576: {
        slidesPerView: 2,
  
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 48,
      },
    }}
    modules={[Pagination]}
    
  >
      {Data.map(({id,image,title,intro}) => {
        return (
          <SwiperSlide className="works_card" key={id}>
            <img src={image} alt="" className="works_img" />

            <h3 className="works_name">{title}</h3>
            <p className="works_intro">{intro}</p>
          </SwiperSlide>
        )
      })}
       
    </Swiper>
    
    
    
    
    
    
    
    </motion.section>
  )
}

export default Works