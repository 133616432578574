import React from 'react';
import "./Contact.css"

const Contact = () => {
  return (
    <section className="contact section" id="contact">
          <h2 className="section_title">Contact</h2>
          <span className="section_subtitle">連絡先</span>
          <div className="contact_container container grid">
    <div className="contact_content">
  <div className="contact_info">
<div className="contact_card">
  <i className="bx bx-mail-send contact_icon"></i>
  <h3 className="contact_title">メールアドレス</h3>
  <span className="contact_data">vga29-wc230001@sankogakuen.jp</span>
</div>

<div className="contact_card">
  <i className="bx bx-phone-call contact_icon"></i>
  <h3 className="contact_title">電話</h3>
  <span className="contact_data">080-7582-0066</span>
  
</div>



  </div>





    </div>


    <div className="contact_content">
      <form  className="contact_form">
        <div className="contact_form-div">
          <label className="contact_form-tag">Name</label>
          <input type="text" name="name" className="contact_form-input"  placeholder="お名前をご入力ください"/>
        </div>

        <div className="contact_form-div">
          <label className="contact_form-tag">Mail</label>
          <input type="email" name="email" className="contact_form-input"  placeholder="メールアドレスをご入力ください"/>
        </div>

        <div className="contact_form-div contact_form-area">
          <label className="contact_form-tag">Project</label>
         <textarea name="project" className="contact_form-input" cols="30" rows="10" placeholder="お問い合わせ"></textarea>
        </div>
        <button className="button button-flex button-o">Submit</button>



      </form>
    </div>


          </div>
    </section>
  )
}

export default Contact