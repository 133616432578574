import React from 'react'
import {motion} from "framer-motion"



const scrollVariants = {
  initial:{
    opacity:0,
  },

  animate:{
    opacity:1,
    transition:{
      duration:3.5,
    },
  }
};
const Scroll = () => {
  return (
    <motion.div className="home_scroll" variants={scrollVariants} initial="initial" animate="animate">
    <a href="#about" className="home_scroll-button button-flex">
    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" id="mouse-alt"><path class="wheel" fill="var(--title-color)" d="M12,6a1,1,0,0,0-1,1V9a1,1,0,0,0,2,0V7A1,1,0,0,0,12,6Zm0-4A7,7,0,0,0,5,9v6a7,7,0,0,0,14,0V9A7,7,0,0,0,12,2Zm5,13A5,5,0,0,1,7,15V9A5,5,0,0,1,17,9Z"></path></svg>
    <span className="home_scroll-name">Scroll Down</span>
    <i class="uil uil-arrow-down home_scroll-arrow"></i>
    </a>
</motion.div>

  )
}

export default Scroll