import React, { useRef } from 'react';
import "./About.css";
import AboutImg from "../../assets/about.png";
import CV from "../../assets/CV.pdf";
import {motion} from "framer-motion"

const aboutvariants = {
  initial:{
    
    x:-300,
    opacity:0
  },
  animate:{
   
    x:0,
    opacity:1,
    transition:{
      duration:1,
      staggerChildren:0.2,
      delayChildren:0.2,

    },
  }
}




const About = () => {
  
  

  return (
    <motion.section className="about section" id="about" 
    variants={aboutvariants}
    initial="initial"
   
  　whileInView="animate">
        <motion.h2 className="section_title" variants={aboutvariants}>About Me</motion.h2>
        <motion.span className="section_subtitle" variants={aboutvariants}>私について</motion.span>

        <motion.div className="about_container container grid" variants={aboutvariants}>
            <img src={AboutImg} alt="" className="about_img" />
            <div className="about_data">
               
                <p className="about_intro">私は中国から来まして、日本語学校に2年、今年は4年目です。卒業するまでに自力でECサイトを開発するという目標をもって、現在サイトのバックエンドを構築することに取り組んでいきます。頑張り続ければ誰にも負けるわけがないとの姿勢で、どんどん新しいものをチャレンジしたいと思います。どうぞよろしくお願い致します。</p>
                <a download="" href={CV} className="button button-flex button-o">履歴書
                <svg xmlns="http://www.w3.org/2000/svg"　style={{marginLeft:"5px"}} width="20px" height="20px" viewBox="0 0 24 24" id="file-download-alt"><path fill="var(--container-color)" d="M8,8a1,1,0,0,0,0,2H9A1,1,0,0,0,9,8Zm5,12H6a1,1,0,0,1-1-1V5A1,1,0,0,1,6,4h5V7a3,3,0,0,0,3,3h3v2a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.29.29,0,0,0-.1,0A1.1,1.1,0,0,0,12.06,2H6A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3h7a1,1,0,0,0,0-2ZM13,5.41,15.59,8H14a1,1,0,0,1-1-1ZM14,12H8a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm6.71,6.29a1,1,0,0,0-1.42,0l-.29.3V16a1,1,0,0,0-2,0v2.59l-.29-.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l2-2A1,1,0,0,0,20.71,18.29ZM12,18a1,1,0,0,0,0-2H8a1,1,0,0,0,0,2Z"></path></svg>
                </a>
                
                
            </div>
        </motion.div>
    </motion.section>
  )
}

export default About