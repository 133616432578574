import React from 'react';
import "./Scrollup.css"

const Scrollup = () => {
    window.addEventListener("scroll",function () {
        const scrollUp = document.querySelector('.scrollup');
        if (this.scrollY >= 300) scrollUp.classList.add
        ("show-scroll");
        else scrollUp.classList.remove("show-scroll");
    });
  return (
    <a href="#" className="scrollup">
        <i className="uil uil-arrow-up scrollup_icon"></i>
    </a>
  )
}

export default Scrollup