import React, { useState } from 'react'

const Backend = () => {
  const [toggleState,setToggleState] = useState(0);
  const toggleTab = (index) => {
    setToggleState(index);
  }
  return (
    <div className="skills_content">
      <h3 className="skills_title">バックエンド</h3>
      <div className="skills_box">
         <div className="skills_group">
            <div className="skills_data">
        <i class="uil uil-check-circle"></i>
    　　　　　　<div>
            <h3 className="skills_name">PHP</h3>
            <span className="skills_level">B</span>
            </div>
            </div>

            <div className="skills_data">
        <i class="uil uil-check-circle"></i>
　　　　　　<div>
            <h3 className="skills_name">Java</h3>
            <span className="skills_level">A</span>
          </div>
            </div>

            <div className="skills_data">
        <i class="uil uil-check-circle"></i>
  　　　　　<div>
            <h3 className="skills_name">Oracle</h3>
            <span className="skills_level">A</span>
          </div>
            </div>

            <div className="skills_data">
        <i class="uil uil-check-circle"></i>
  　　　　　　　<div>
            <h3 className="skills_name">Node.js</h3>
            <span className="skills_level">C</span>
            </div>
            </div>
          
          </div>
      </div>
    

           
<span className="skills_button" onClick={() => toggleTab(3)}>Details<i className="uil uil-arrow-right skills_button-icon"></i></span>

        
<div className={toggleState === 3 ? "skills_modal active-modal" : "skills_modal"}>
            <div className="skills_modal-content">
            <i class="uil uil-times skills_modal-close" onClick={() => toggleTab(0)}></i>
            <h3 className="skills_modal-title">バックエンド</h3>
            <p className="skills_modal-intro">主にSpringbootとMybatis二つのJavaフレームワークを使い、データベースと連携し、管理システムを構築しています。</p>
            </div>

</div>

</div>
        
  )
}

export default Backend