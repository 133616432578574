import React from 'react';
import {motion} from "framer-motion"


const textVariants = {
  initial:{
    x:-500,
    opacity:0,
  },

  animate:{
    x:0,
    opacity:1,
    transition:{
      duration:1,
      staggerChildren:0.1,
    },
  }
};

const Data = () => {
  return (
    <motion.div className="home_data" variants={textVariants} initial="initial" animate="animate">
        <motion.h1 className="home_title"  variants={textVariants}>オウ　セイヨウ
        </motion.h1>
        <motion.h3 className="home_intro" variants={textVariants}>東京みらいAI&IT専門学校</motion.h3>
        <motion.p className="home_text" variants={textVariants}>WEBクリエイター科2年</motion.p>
        <motion.a href="#about" className="button button-flex button-o" variants={textVariants}>
            Know More
        </motion.a>
    </motion.div>
  )
}

export default Data