import React from 'react';
import "./Footer.css"

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer_container container">
            <ul className="footer_list">
                <li>
                    <a href="#about" className="footer_link">About</a>
               </li>

               <li>
                    <a href="#skills" className="footer_link">Skills</a>
               </li>
               
               <li>
                    <a href="#work" className="footer_link">Works</a>
               </li>

               
               
            </ul>

            <div className="footer_social">
            <a href="https://www.linkedin.com/feed/" className="footer_social-link" target="_blank">
        <i class="bx bxl-linkedin"></i>

        </a>

        <a href="https://www.instagram.com/" className="footer_social-link" target="_blank">
        <i class="bx bxl-instagram"></i>
        </a>

        <a href="https://x.com/home" className="footer_social-link" target="_blank">
        <i class="bx bxl-twitter"></i>
        </a>
            </div>
            <span className="footer_copy">
                Copyright &#169;All rights eserved
            </span>

        </div>
    </footer>
  )
}

export default Footer